import { Message, MessageContentItem } from '@/features/tools/types'
import toast from 'react-hot-toast'

// NOTE: Unused!
export const processTextStream = async (
  stream: ReadableStream,
  onChunk: Function,
) => {
  const reader = stream.getReader()
  const decoder = new TextDecoder()
  const output = ''

  while (true) {
    const { done, value } = await reader.read()
    if (done) break
    onChunk(decoder.decode(value))
  }
  return output
}

export interface BaseStreamEvent {
  type: string
}

export interface GenericNotificationEvent extends BaseStreamEvent {
  type: 'notification'
  variant: 'info' | 'success' | 'warning' | 'error'
  message: string
}

export interface TextCreatedEvent extends BaseStreamEvent {
  type: 'textCreated'
}

export interface TextDeltaEvent extends BaseStreamEvent {
  type: 'textDelta'
  value: string | undefined
}

export interface TextDoneEvent extends BaseStreamEvent {
  type: 'textDone'
}

export enum PluginCallStatus {
  'pending' = 'pending',
  'completed' = 'completed',
  'failed' = 'failed',
}

export interface PluginCallCreatedEvent extends BaseStreamEvent {
  id: string
  type: 'pluginCallCreated'
  function: string
  displayMessage: string
}

export interface PluginCallDoneEvent extends BaseStreamEvent {
  id: string
  type: 'pluginCallDone'
}

export interface PluginUserFacingResultText {
  type: 'text'
  text: string
}

export interface PluginUserFacingResultCode {
  type: 'code'
  code: string
}

export interface PluginUserFacingResultImage {
  type: 'image'
  url: string
  prompt: string
}

export interface PluginUserFacingResultLinks {
  type: 'links'
  links: {
    title: string
    url: string
  }[]
}

export interface PluginUserFacingResultFile {
  type: 'file'
  mimeType: string
  url: string
}

export interface PluginCallUpdateEvent extends BaseStreamEvent {
  id: string
  type: 'pluginCallUpdate'
  displayMessage: string
}

export type PluginUserFacingResult =
  | PluginUserFacingResultText
  | PluginUserFacingResultCode
  | PluginUserFacingResultImage
  | PluginUserFacingResultLinks
  | PluginUserFacingResultFile

export interface PluginCallResultEvent extends BaseStreamEvent {
  id: string
  type: 'pluginCallResult'
  success: boolean
  result: PluginUserFacingResult
  displayMessage: string
}

export type StreamEvent =
  | GenericNotificationEvent
  | TextCreatedEvent
  | TextDeltaEvent
  | TextDoneEvent
  | PluginCallCreatedEvent
  | PluginCallDoneEvent
  | PluginCallResultEvent
  | PluginCallUpdateEvent

export const processEventStream = async (
  stream: ReadableStream,
  updateMessage: Function,
) => {
  const message: Message = {
    role: 'assistant',
    content: [],
  }

  const reader = stream.getReader()
  const decoder = new TextDecoder()
  let contentIndex = -1

  while (true) {
    const { done, value } = await reader.read()
    if (done) break

    const decodedValue = decoder.decode(value)
    const lines = decodedValue.split('\n')
    for (const line of lines) {
      if (!line.trim()) continue

      const event: StreamEvent = JSON.parse(line)

      if (event.type === 'notification') {
        switch (event.variant) {
          case 'info':
            toast(event.message, {
              icon: 'ℹ️',
            })
            break
          case 'success':
            toast.success(event.message)
            break
          case 'warning':
            toast(event.message, {
              icon: '⚠️',
            })
            break
          case 'error':
            toast.error(event.message)
            break
        }
      } else if (event.type === 'textCreated') {
        message.content.push({
          type: 'text',
          text: '',
        })
        contentIndex++
        updateMessage(message)
      } else if (event.type === 'textDelta') {
        message.content[contentIndex].text += event.value
        updateMessage(message)
      } else if (event.type === 'pluginCallCreated') {
        message.content.push({
          type: 'pluginCall',
          id: event.id,
          function: event.function,
          status: PluginCallStatus.pending,
          result: undefined,
          displayMessage: event.displayMessage,
        })
        contentIndex++
        updateMessage(message)
      } else if (event.type === 'pluginCallUpdate') {
        patchPluginCall(message, event.id, (content) => ({
          ...content,
          displayMessage: event.displayMessage,
        }))
        updateMessage(message)
      } else if (event.type === 'pluginCallResult') {
        patchPluginCall(message, event.id, (content) => ({
          ...content,
          status: event.success
            ? PluginCallStatus.completed
            : PluginCallStatus.failed,
          result: event.result,
          displayMessage: event.displayMessage,
        }))
        updateMessage(message)
      }
    }
  }
}

function patchPluginCall(
  message: Message,
  id: string,
  updater: (content: MessageContentItem) => MessageContentItem,
) {
  const index = message.content.findIndex((content) => content.id === id)
  if (index === -1) {
    console.error('Plugin call update event received for unknown plugin call')
    return
  }
  const result = updater(message.content[index])
  if (result) {
    message.content[index] = result
  }
}
